<template>
  <div class="login" v-if="!isProcessing">
    <v-img class="login__top" :src="visualImage.icon" />
    <div class="login__benefits" v-for="item in privileges" :key="item.rank">
      <h2 class="login__benefits__title">入会特典 {{ encircledNumber(item.rank) }}</h2>
      <p class="login__benefits__appeal">
        <span class="login__benefits__appeal__underline">{{ item.title }}</span>
      </p>
      <p class="login__benefits__description">{{ item.description }}</p>
      <v-carousel class="login__benefits__carousel" v-if="item.images.length>0" cycle interval="1500" hide-delimiter-background show-arrows-on-hover>
        <!-- v-carouselのみだと初回アクセス時画像切り替えごとに画像を読み込みチラつくのでv-imgをネストしてeager属性を付けて先に読み込ませる -->
        <v-carousel-item class="login__benefits__carousel__item" eager v-for="img in item.images" :key="img" >
          <v-img class="login__benefits__carousel__item__img" :src="img" eager />
        </v-carousel-item>
      </v-carousel>
    </div>
    <!-- 入会ボタン -->
    <div class="login__join">
      <v-btn class="login__join__btn" @click="$router.push({ name: 'signup' })">
        <span class="login__join__btn__text">入会する</span>
        <span class="login__join__btn__text--small">月額1,480円</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import string from '@/assets/lib/string'

export default {
  name: 'login',
  mixins: [string],
  async mounted () {
    const promises = []
    // トップ画像が未取得なら取得
    if (!this.visualImage) promises.push(this.$store.dispatch('settingImages/getSettingImage', 'visual'))
    // 特典画像が未取得なら取得
    if (this.privileges.length === 0) promises.push(this.$store.dispatch('settingPrivileges/getSettingPrivileges'))

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {Object} top画像
     */
    visualImage () {
      return this.$store.getters['settingImages/settingImage']('visual')
    },
    /**
     * @return {Object[]} 入会特典で表示するタイトルと説明文
     */
    privileges () {
      return this.$store.getters['settingPrivileges/settingPrivileges']
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.login {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: 100vh;
  padding: 0 $padding_width;
  margin: $header_height auto 0;
  text-align: center;
  &__top {
    width: 100vw;
    max-width: $max_width;
    height: 48.6vw;
    max-height: calc(#{$max_width} * 0.486);
    margin: 0 -#{$padding_width};
    background-color: #829837;
  }
  &__benefits {
    margin-top: 94px;
    &:nth-of-type(2) {
      margin-top: 40px;
    }
    &__title {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
    &__appeal {
      margin: 10px auto 0;
      font-size: 1.6rem;
      line-height: 2rem;
      text-align: center;
      &__underline {
        display: inline-block;
        background: linear-gradient(0deg, $orange_lighten_color 0%, $orange_lighten_color 50%, transparent 50%, transparent 100%);
      }
    }
    &__description {
      margin: 20px auto 0;
      font-size: 1.2rem;
      text-align: justify;
      white-space: pre-wrap;
    }
    &__carousel {
      width: 100%;
      height: 298px !important;
      margin-top: 20px;

      @media (max-width: $max_width) {
        height: calc((100vw - #{$padding_width} * 2) * 100 / 191) !important;
      }
      & ::v-deep .v-carousel__controls {
        height: 25px;
        & .v-btn--icon {
          width: 5px;
          height: 25px;
          margin: 0 1px;
        }
        & .v-icon {
          opacity: 1;
          &::before {
            width: 5px;
            height: 5px;
            content: "";
            background-color: $white_color;
            border-radius: 50%;
          }
        }
        & .v-btn--active {
          &::before {
            opacity: 0;
          }
          & .v-icon::before {
            background-color: $gray_color;
          }
        }
      }
      &__item {
        & ::v-deep .v-carousel__item {
          width: 100%;
          height: 298px !important;
        }
        &__img {
          width: 100%;
          height: 298px !important;

          @media (max-width: $max_width) {
            height: calc((100vw - #{$padding_width} * 2) * 100 / 191) !important;
          }
        }
      }
    }
  }
  &__join {
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: $hover_btn_zindex;
    width: 100vw;
    max-width: $max_width;
    padding: 0 30px 30px;
    text-align: right;
    pointer-events: none;
    transform: translate(-50%, 0);
    &__btn {
      color: $white_color;
      pointer-events: auto;
      border-radius: 50%;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      &.v-btn:not(.v-btn--round).v-size--default {
        width: 100px;
        height: 100px;
      }
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $orange_color;
      }
      & ::v-deep .v-btn__content {
        display: block;
      }
      &__text {
        display: block;
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 2.2rem;
        &--small {
          @extend .login__join__btn__text;
          margin-top: 5px;
          font-size: 1rem;
          font-weight: normal;
          line-height: 1.2rem;
        }
      }
    }
  }
}
</style>
